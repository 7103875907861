import { Inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  UrlSegment,
  CanActivateChild,
  CanLoad
} from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { takeSnapshot } from '../../util';
import {RoutesPath} from '../../../../routes';

@Injectable()

export class UnAuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return this.checkAuth();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    return this.checkAuth();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> {
    return this.checkAuth();
  }

  private checkAuth(): boolean | Promise<boolean> {
    const isAuthenticated = takeSnapshot(this.authService.isAuthenticated$, null);
    if (isAuthenticated) {
      return this.router.navigate([`/${RoutesPath.main}`]);
    }
    return !isAuthenticated;
  }
}
