export const environment = {
  production: false,
  serviceWorker: true,
  api: {
    url: 'https://dev.apigw.bopredicta.raks.com.ua',
  },
  firebase: {
    apiKey: 'AIzaSyDa5Ed4w9aokZrntnSoczxEhdYlo65LVL4',
    authDomain: 'bopredicta-c0bc1.firebaseapp.com',
    projectId: 'bopredicta-c0bc1',
    storageBucket: 'bopredicta-c0bc1.appspot.com',
    messagingSenderId: '1084657667294',
    appId: '1:1084657667294:web:dceabe002832c122f5064f',
    vapidKey: 'BIHv4ezXGwvgEQNk2ODKp7k7IK0gboUQbxVlA8ZHDryAp1ayG9V8wx-LhAmq64NxP-AtraAPEKkSohvKoxTTToE'
  },
};
