import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {RoutesPath} from '../routes';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutesPath.auth
  },
  {
    path: RoutesPath.common,
    loadChildren: () => import('./common/common-routing.module').then( m => m.CommonRoutingModule)
  },
  {
    path: RoutesPath.main,
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: RoutesPath.auth,
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
