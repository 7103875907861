import {
  Component,
  ViewEncapsulation
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {BaseModalComponent} from '../base.modal.component';
import {WindowService} from '../../services';

@Component({
  selector: 'app-add-to-homescreen',
  templateUrl: './add-to-homescreen.component.html',
  styleUrls: ['./add-to-homescreen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddToHomescreenComponent extends BaseModalComponent{

  osVersion;

  constructor(public modalController: ModalController, public windowService: WindowService) {
    super(modalController);
    this.osVersion = windowService.osVersion;
  }
}
