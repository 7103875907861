export interface AuthDataI {
  pin: number;
  type?: 'twitter' | 'google' | 'facebook' | 'email';
  email: string;
  token: string;
  password: string;
  username: string;
  isSocial?: boolean;
  accessToken: string;
  isRegistered: boolean;
  sendNotifications: boolean;
  webNotificationToken?: string | null;
}

export class AuthConstants {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly SID = 'sid';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly UID = 'uid';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly SID_HASH = 'sidHash';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly CURRENT_USER = 'current-user';
}

export interface LoginData {
  email: string;
  password: string;
}


export interface ResetPasswordData {
  email: string;
  password: string;
  pin: string;
  token: string;
}

export interface ResetEmailData {
  email: string;
  pin: string;
  token: string;
}

export interface LoginResponse {
  ok: boolean;
  sid: string;
  uid: string;
}

export interface RegisterData {
  ok: boolean;
  sid?: string;
  uid?: string;
  type?: string;
  token?: string;
}

export interface PushSettings {
  'app::updates': 'yes' | 'no';
  'follower::new': 'yes' | 'no';
  'matchday::completed': 'yes' | 'no';
  'matchday::started': 'yes' | 'no';
  'streak::reminder': 'yes' | 'no';
}

export interface ProfileData {
  uid: string;
  params: { hash: string; firstName: string; lastName: string };
  friends: { following: number; followers: string };
  credentials: {type: 'email' | 'username' | 'firstName' | 'lastName'; credential: string}[];
  push: {
    settings: PushSettings;
  };
}

export class Profile {

  public data: ProfileData;

  get email() {
    return this.data.credentials.find(c => c.type === 'email')?.credential;
  }

  get username() {
    return this.data.credentials.find(c => c.type === 'username')?.credential;
  }

  get firstName() {
    return (this.data.credentials.find(c => c.type === 'firstName')?.credential || this.data?.params?.firstName);
  }

  get lastName() {
    return (this.data.credentials.find(c => c.type === 'lastName')?.credential || this.data?.params?.lastName);
  }

  get following() {
    return this.data.friends.following;
  }

  get followers() {
    return this.data.friends.followers;
  }

  constructor(profileData: ProfileData) {
    this.data = profileData;
  }
}

