import {Injectable, NgZone} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { ToastService } from '../services';
import {ERRORS_MESSAGE, capitalizeFirstLetter} from '../util';

@Injectable({providedIn: 'root'})

export class ErrorInterceptor implements HttpInterceptor {


  constructor(
    private readonly toastService: ToastService,
    private readonly ngZone: NgZone) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.error?.message === ERRORS_MESSAGE.NOT_AUTHORIZED
            || error?.message === ERRORS_MESSAGE.NOT_AUTHORIZED) { return throwError(error); }
          //Show only "Houston" message
          const errorMsg = this.getErrorMessage(error);
          if (errorMsg){
            this.ngZone.run(_ => {
              this.toastService.presentToast(errorMsg, {
                position: 'top',
                duration: 5000,
                cssClass: 'error-toast',
                color: 'danger',
                buttons: [{
                  text: 'OK',
                  role: 'cancel',
                  handler: () => {}
                }]
              });
            });
          }
          return throwError(error);
        })
      );
  }

  getErrorMessage(error: HttpErrorResponse): string {
    let errorMsg = 'Houston we have a problem, not connected to internet.';
    if (error?.status === 504 || error?.status === 502 || error?.status === 500 || error?.status === 0) {
      errorMsg = 'Houston we have a problem, not connected to internet.';
    } else if (error?.error && error?.error?.data && typeof error?.error?.data === 'string') {
      // errorMsg = error.error.data;
      errorMsg = '';
    } else if (error?.error && error?.error?.message) {
      // errorMsg = capitalizeFirstLetter(error?.error?.message);
      errorMsg = '';
    } else {
      // errorMsg = !!(error?.message) ? capitalizeFirstLetter(error?.message) : errorMsg;
      errorMsg = !!(error?.message) ? '' : errorMsg;
    }
    return errorMsg;
  }
}
