import {ApiService} from '../api';
import {Injectable} from '@angular/core';
import {FirabaseConfigs} from './firabase.types';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FirabaseService {

  constructor(private api: ApiService, private afMessaging: AngularFireMessaging) { }

  getPushConfig() {
    return this.api.post<{ok: boolean; data: FirabaseConfigs}>(`api/user/push/web/config` );
  }

  savePushToken(token: string) {
    return this.api.post<{ok: boolean}>(`api/user/push/web/token`, {token});
  }

  getAndSavePushToken(){
    return this.afMessaging.getToken.pipe(
      switchMap((token) => {
        if (token) {
          return this.savePushToken(token);
        }
        return of(null);
      })
    );
  }
}
