import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthSidInterceptor} from './auth-sid.interceptor';
import {ErrorInterceptor} from './error.interceptor';
import {AuthErrorInterceptor} from './auth-error.interceptor';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthSidInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthErrorInterceptor,
    multi: true
  },
];

