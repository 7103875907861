export class ValidationConstants {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly EMAIL_REGEXP = new RegExp('^[\\w-.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly BRACKET_UID_REGEXP = new RegExp('^[a-zA-Z]\\d{4}$');
}

export interface CredentialUserResponse {
  confirmed?: number;
  rb: number;
  uid: number;
}

