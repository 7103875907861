import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {environment} from '../environments';
import {IonicModule} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ApiModule, CustomReusingStrategy, GlobalErrorHandler} from './common/services';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {interceptorProviders} from './common/interceptors';
import {AuthGuard, AuthSidGuard, UnAuthGuard} from './common/guards';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      animated: false,
      swipeBackEnabled: false,
      hardwareBackButton: false,
    }),
    CommonModule,
    AppRoutingModule,
    ApiModule.forRoot({apiUrl: environment.api.url}),
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    BrowserAnimationsModule
  ],
  providers: [
    AuthGuard,
    AuthSidGuard,
    UnAuthGuard,
    {provide: RouteReuseStrategy, useClass: CustomReusingStrategy},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    interceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
