import { Injectable } from '@angular/core';
import { WindowService } from '../window';
import {ApiService} from '../api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(public window: WindowService, private api: ApiService) {
  }

  windowOpen(url: string){
    const target = this.window.isIOS && this.window.isSafari ? '_top' : '_blank';
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    window.open(url, target, params);
  }

  shareTwitter(url: string, text = '', hashtags = ''){
    const openUrl = `https://twitter.com/share?text=${encodeURIComponent(text)}&url=${url}&hashtags=${hashtags || ''}`;
    this.windowOpen(openUrl);
  }

  shareFacebook(url: string, text = ''){
    this.windowOpen(
      'https://www.facebook.com/sharer/sharer.php?' +
      'u=' + encodeURIComponent(url) +
      '&quote=' + encodeURIComponent(text)
    );
  }

  shareWhatsapp(text = ''){
    this.windowOpen(
      'https://wa.me/?text=' + encodeURIComponent(text)
    );
  }

  makeScreenShot(url: string){
    return this.api.post<{ok: boolean; url: string}>(`api/screenshot/fetch`, {
      url, width: window.screen.width, height: window.screen.height})
      .pipe(map((res) => ({url: this.api.apiUrl + '/' + res.url})));
  }
}
