import { Injectable } from '@angular/core';
import {ApiService} from '../api';
import {
  AuthConstants,
  AuthDataI,
  LoginData,
  LoginResponse, Profile,
  ProfileData, PushSettings,
  RegisterData, ResetEmailData,
  ResetPasswordData
} from './auth.types';
import {LocalStorageService} from '../storage';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {AUTH_ERROR_EVENT, EventService, USER_LOGOUT_EVENT} from '../event';
import {FirabaseService} from '../firabase';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get token(): string {
    return this.storageService.getItem(AuthConstants.SID);
  }

  get uid(): string {
    return this.storageService.getItem(AuthConstants.UID);
  }

  public authUserData: Partial<AuthDataI> = {};
  currentUser$: BehaviorSubject<Profile | null> = new BehaviorSubject<Profile | null>(null);
  isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService,
              public events: EventService,
              private firabaseService: FirabaseService,
              private storageService: LocalStorageService) {
    events.listening(AUTH_ERROR_EVENT).subscribe(() => {
      this.logout();
    });
    this.isAuthenticated$
      .pipe(switchMap((isAuthenticated) => (isAuthenticated ? this.checkSid() : of(null))))
      .subscribe((res) => {
        if (res?.ok) {
          this.firabaseService.getAndSavePushToken()
            .subscribe((result) => { });
        }
      });

    if (this.token) {
      this.isAuthenticated$.next(true);
    }
  }

  login(loginData: LoginData){
    return this.authMethod<LoginResponse, LoginData>('auth/login', loginData);
  }

  register(data: AuthDataI){
    return this.authMethod<RegisterData, LoginData>('auth/register', data);
  }

  isRegisterEmail(email: string){
    return this.api.post<{isRegistered: boolean}>(`auth/registered/email/${email}`, {email});
  }

  sidUidLogin(data: {sid: string; uid: string}) {
    if (data.sid) {
      this.storageService.setItem(AuthConstants.SID, data.sid);
    }
    if (data.uid) {
      this.storageService.setItem(AuthConstants.UID, data.uid);
    }
    return this.checkSid();
  }

  authMethod<T extends {sid?: string; uid?: string}, S>(endpoint: string, data: S): Observable<T> {
    return this.api.post<T>(endpoint, data)
      .pipe(
        switchMap((res) => {
          if (res.sid) {
            this.storageService.setItem(AuthConstants.SID, res.sid);
          }
          if (res.uid) {
            this.storageService.setItem(AuthConstants.UID, res.uid);
          }
          if (!!res.sid) {
            this.isAuthenticated$.next(true);
          }
          return of(res);
        }));
  }

  checkSid(): Observable<{ok: boolean}> {
    const uid = this.storageService.getItem(AuthConstants.UID);
    return this.api.post(`api/check/${uid}`)
      .pipe<any>(tap((res) => {
        if(res.ok === false) { this.isAuthenticated$.next(false); }
      }));
  }

  logout(): void {
    this.storageService.removeItem(AuthConstants.SID);
    this.storageService.removeItem(AuthConstants.UID);
    this.isAuthenticated$.next(false);
    this.events.publish(USER_LOGOUT_EVENT);
  }

  sendPin(data: {email: string}){
    return this.api.post<{ok: boolean; token: string; type: string}>('auth/pin/forgot/password', data);
  }

  changePassword(data: ResetPasswordData){
    return this.authMethod<LoginResponse, ResetPasswordData>('auth/pin/forgot/password/change', data);
  }

  changeFirstLastName(firstName: string, lastName: string){
    return this.api.post<ProfileData>('api/user/my/firstlast', {firstName, lastName});
  }

  changeUsername(username: string){
    return this.api.post<ProfileData>('api/user/my/username', {username});
  }

  changeEmail(data: ResetEmailData | { email: string } ){
    return this.api.post<ProfileData | {ok: boolean; token: string; type: string}>('api/user/my/email', data);
  }

  changeNotificationsSetting(data: {settings: PushSettings}){
    this.api.post<ProfileData>('api/user/push/settings', data)
      .pipe(map(res => new Profile(res)))
      .subscribe((p) => this.currentUser$.next(p));
    return this.currentUser$;
  }

  profile(){
    this.api.post<ProfileData>('api/user/my')
      .pipe(map(res => new Profile(res)))
      .subscribe((p) => this.currentUser$.next(p));
    return this.currentUser$;
  }
}
