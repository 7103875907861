import { Component } from '@angular/core';
import {AddToHomeScreenService, FixtureService} from './common/services';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private fixtureService: FixtureService,
    private athsService: AddToHomeScreenService,
    private afMessaging: AngularFireMessaging
  ) {
    // A2HS - START
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
    athsService.trackStandalone();
    window.removeEventListener('appinstalled', this.onAppInstalled);
    window.addEventListener('appinstalled', this.onAppInstalled);
    // A2HS - END
    this.listen();
  }

  onAppInstalled = () => {
    this.athsService.trackInstalled();
  };

  onBeforeInstallPrompt = (e: any) => {
    // show the add button
    this.athsService.promptIntercepted = true;
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
    e.preventDefault();
    // Stash the event so it can be displayed when the user wants.
    this.athsService.deferredPrompt = e;
    this.athsService.promptSaved = true;
  };

  listen() {
    this.afMessaging.messages
      .subscribe((message) => {
        console.log('message: ', message);
      });

    const broadcast = new BroadcastChannel('count-channel');
    // Listen to the response
    broadcast.onmessage = (event) => {
      if(event?.data?.data?.click_action) {
        // Do not reload page if we already open current matchday fixture list
        if(event?.data?.data?.click_action.includes('matchday-fixture-list')){
          const urlArr = event?.data?.data?.click_action.split('/');
          const openedMatchDayLeagueId = urlArr[urlArr.length - 1];
          if (this.fixtureService.openedMatchDayLeagueId !== +openedMatchDayLeagueId) {
            window.location.href = event?.data?.data?.click_action;
          }
        }else if(window.location.href !== event?.data?.data?.click_action){
          window.location.href = event?.data?.data?.click_action;
        }
      }
    };
  }
}
