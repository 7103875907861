import { Injectable } from '@angular/core';
import { ApiService } from '../api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {AuthService, LoginResponse} from '../auth';
import {GoogleLoggedInData, GoogleLoginData, GoogleTokenByCodeResponse} from './social-google.types';

@Injectable()
export class SocialGoogleService {

  private scopes: Array<string> = [
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
    'openid'
  ];

  constructor(private api: ApiService, public authService: AuthService) {
  }

  getUserGoogleLoginCodeUrl() {
    return new Promise((resolve, reject) => {
      const done = new Subject();

      this.api.post('auth/google/redirect/url', {scopes: this.scopes}).pipe(takeUntil(done)
        ).subscribe((data: any) => {
        resolve(data);
        done.next();
        done.complete();
      }, (error) => {
        reject(error);
      });
    });
  }

  getUserGoogleTokenByCode(code: string): Promise<GoogleTokenByCodeResponse> {
    return new Promise((resolve, reject) => {
      const done = new Subject();
      this.api.post('auth/google/code', {code})
        .pipe(takeUntil(done)
      ).subscribe((data: any) => {
        resolve(data);
        done.next();
        done.complete();
      }, (error) => {
        reject(error);
      });
    });
  }

  loginGoogle(googleLoginData: Partial<GoogleLoginData>) {
    this.authService.authUserData.accessToken = googleLoginData.accessToken;
    return this.authService.authMethod<GoogleLoggedInData, Partial<GoogleLoginData>>('auth/google/login', googleLoginData);
  }
}
