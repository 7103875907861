import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalStorageService, AuthConstants} from '../services';

@Injectable({providedIn: 'root'})
export class AuthSidInterceptor implements HttpInterceptor {

    constructor(
        private store: LocalStorageService,
    ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const token = this.store.getItem(AuthConstants.SID);
      if (token) {
        // Add SID as param
        request = request.clone({
          body: Object.assign(request.body || {}, { sid: token })
        });
      }
      return next.handle(request) as Observable<HttpEvent<unknown>>;
    }
}
