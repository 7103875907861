import {Component} from '@angular/core';
import { BaseComponent } from './base.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-base-modal-component',
  template: ''
})

export class BaseModalComponent extends BaseComponent {


  constructor(public modalController: ModalController) {
    super();
  }

  dismiss(data?: any) {
    this.modalController.dismiss(data);
  }
}
