import { Injectable } from '@angular/core';
import { ApiService } from '../api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth';
import {FacebookLoggedInData, FacebookLoginData, FbTokenByCodeResponse} from './social-facebook.types';

@Injectable()
export class SocialFacebookService {

  private scopes: Array<string> = ['email'];

  constructor(private api: ApiService, public authService: AuthService) {
  }

  getUserFacebookLoginCodeUrl() {
    return new Promise((resolve, reject) => {
      const done = new Subject();
      this.api.post('auth/facebook/redirect/url', {scopes: this.scopes})
        .pipe(takeUntil(done))
        .subscribe((data: any) => {
        resolve(data);
        done.next();
        done.complete();
      }, (error) => {
        reject(error);
      });
    });
  }

  getUserFacebookTokenByCode(code: string): Promise<FbTokenByCodeResponse> {
    return new Promise((resolve, reject) => {
      const done = new Subject();
      this.api.post('auth/facebook/code', {code})
        .pipe(takeUntil(done))
        .subscribe((data: any) => {
        resolve(data);
        done.next();
        done.complete();
      }, (error) => {
        reject(error);
      });
    });
  }

  loginFacebook(fbLoginData: Partial<FacebookLoginData>) {
    this.authService.authUserData.accessToken = fbLoginData.accessToken;
    return this.authService.authMethod<FacebookLoggedInData, Partial<FacebookLoginData>>('auth/facebook/login', fbLoginData);
  }
}
