import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export interface ApiResponse {
  ok: boolean;
}

export interface ApiConfig {
  apiUrl: string;
}

export const API_CONFIG = new InjectionToken('ApiConfig');

export abstract class Api {
  abstract apiUrl: string;

  abstract get<T>(endpoint: string, params?: any, reqOpts?: { [key: string]: any }): Observable<T>;
  abstract post<T>(endpoint: string, body: any, reqOpts?: any): Observable<T>;
  abstract put<T>(endpoint: string, body: any, reqOpts?: any): Observable<T>;
  abstract delete<T>(endpoint: string, reqOpts?: any): Observable<T>;
  abstract patch<T>(endpoint: string, body: any, reqOpts?: any): Observable<T>;

}
