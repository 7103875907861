import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class LoaderService {

  constructor(public loadingController: LoadingController) { }

  showHideAutoLoader(message: string, duration: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message,
        spinner: 'bubbles',
        duration
      }).then((res) => {
        res.present().then(() => { resolve(); });
      });
    });
  }

  showLoader(message: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadingController.create({message, spinner: 'bubbles'})
        .then((res) => {
          res.present().then(() => { resolve(); });
        });
    });
  }

  hideLoader(): void {
    this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });
  }
}
