import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Api, API_CONFIG, ApiConfig} from './api.types';

@Injectable({
  providedIn: 'root'
})

export class ApiService implements Api{

  public apiUrl: string;

  constructor(private http: HttpClient, @Inject(API_CONFIG) private config: ApiConfig) {
    this.apiUrl = config.apiUrl;
  }

  get<T>(endpoint: string = '', params?: any, reqOpts?: { [key: string]: any }): Observable<T> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();

      Object.keys(params).forEach(key => {
        // @ts-ignore
        reqOpts.params = reqOpts.params.set(key, params[key]);
      });
    }
    return this.http.get<T>(this.apiUrl + '/' + endpoint, reqOpts);
  }

  post<T>(endpoint: string, body: any = {}, reqOpts?: any): Observable<T> {
    // @ts-ignore
    return this.http.post<T>(this.apiUrl + '/' + endpoint, body, reqOpts);
  }

  put<T>(endpoint: string, body: any, reqOpts?: any): Observable<T> {
    // @ts-ignore
    return this.http.put<T>(this.apiUrl + '/' + endpoint, body, reqOpts);
  }

  delete<T>(endpoint: string, reqOpts?: any): Observable<T> {
    // @ts-ignore
    return this.http.delete<T>(this.apiUrl + '/' + endpoint, reqOpts);
  }

  patch<T>(endpoint: string, body: any, reqOpts?: any): Observable<T> {
    // @ts-ignore
    return this.http.patch<T>(this.apiUrl + '/' + endpoint, body, reqOpts);
  }
}
