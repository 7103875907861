import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: ''
})

export class BaseComponent implements OnDestroy {

  protected destroy$ = new Subject<void>();
  protected destroyed = false;
  protected progress$ = new BehaviorSubject<boolean>(false);
  protected showError$ = new BehaviorSubject<any>(null);

  protected constructor() {
  }

  get inProgress$(): Observable<boolean> {
    return this.progress$.asObservable();
  }

  get error$(): Observable<any> {
    return this.showError$.asObservable();
  }

  onDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroyed = true;
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
