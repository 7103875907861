
export enum RoutesPath {
  main = 'main',
  auth = 'auth',
  common = 'common',
  home = 'common/home',
  predictions = 'main/predictions',
  stats = 'stats',
  matchdays = 'main/matchdays',
  matchdayNumber = 'main/matchday-number',
  welcome = 'auth/welcome',
  agreement = 'auth/agreement',
  enterPin = 'auth/enter-pin',
  enterUsername = 'auth/enter-username',
  enterEmail = 'auth/enter-email',
  enterPassword = 'auth/enter-password',
  accountSettings = 'account-settings',
  enterPasswordNewUser = 'auth/enter-password-new-user',
  enterPasswordSocialLogin = 'auth/enter-password-social-login',
  forgottenPassword = 'auth/forgotten-password',
  favouriteLeagues = 'main/favourite-leagues',
  friends = 'main/friends',
  friendsSearch = 'main/friends-search',
  userProfile = 'main/user-profile',
  twitterLogin = 'auth/twitter-login/:code',
}
