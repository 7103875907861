import { Injectable } from '@angular/core';
import {ApiService} from '../api';
import {Observable, Subject} from 'rxjs';
import {
  FixtureI,
  FixtureLineupDataI, FixtureStatsDataI, FixtureTimelineDataI,
  MatchdayNotificationI,
  MatchdayPredictionsList,
  PredictionData
} from './fixture.types';
import {BetI} from '../bet';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FixtureService {

  openedMatchDayLeagueId!: number | undefined;

  constructor(private api: ApiService) { }

  getFixture(sid: string): Subject<FixtureI> {
    const s = new Subject();

    this.api.post<FixtureI>('api/fixture', {sid}).subscribe((fixture) => {
      fixture.sportLeagueRound.name = fixture.sportLeagueRound.name.replace(/_|__/g, ' ');
      s.next(fixture);
    }, (e) => {
      s.error(e);
    });

    // @ts-ignore
    return s;
  }

  canPredict(sid: string, fixtureId: number): Observable<{ can: boolean }> {
    // @ts-ignore
    return this.api.post(`api/fixture/canPredict/${fixtureId}`, {sid});
  }

  getGroups(sid: string, fixtureId: number): Observable<{ groups: string[] }> {
    // @ts-ignore
    return this.api.post(`api/groups/${fixtureId}`, {sid});
  }

  fixtureLock(sid: string, fixtureId: number): Observable<BetI> {
    // @ts-ignore
    return this.api.post(`api/fixture/lock/${fixtureId}`, {sid});
  }

  confirmPrediction(sid: string, homePoints: number, awayPoints: number, fixtureId: number) {

    return this.api.post('api/fixture/confirm', {sid, homePoints, awayPoints, fixtureId});
  }

  getFixtureById(fixtureId: number): Observable<FixtureI> {
    // @ts-ignore
    return this.api.post('api/fixture/' + fixtureId, {fixtureId});
  }

  isFixtureGroupsPredicted(sid: string, fixtureId: number): Observable<{ isPredicted: boolean }>{
    // @ts-ignore
    return this.api.post('api/fixture/groups/isPredicted/' + fixtureId, {sid});
  }


  getFixtureByLeague(id: number): Observable<FixtureI[]> {
    return this.api.post(`api/matchday/league/fixtures/${id}`, {id});
  }

  getFixtureByLeagues(ids: number[]): Observable<FixtureI[][]> {
    return this.api.post(`api/matchday/league/fixtures/${ids.join()}`, {ids});
  }

  posPrediction(fixturesPredictions: PredictionData[], matchDayId: number): Observable<any> {
    return this.api.post(`api/user/league/matchday/store/${matchDayId}`, {fixtures: fixturesPredictions});
  }

  getMatchdayPredictionList(matchDayId: number): Observable<MatchdayPredictionsList> {
    return this.api.post<{data: MatchdayPredictionsList}>(`api/user/league/matchday/${matchDayId}`)
      .pipe(map( res => res.data));
  }

  matchdayNotification(data: MatchdayNotificationI): Observable<{ ok: boolean }> {
    return this.api.post<{ ok: boolean }>(`api/user/league/matchday/fixture/notification`, data);
  }

  getFixtureLineup(fixtureId: number): Observable<FixtureLineupDataI> {
    return this.api.post<FixtureLineupDataI>(`api/fixture/lineups/${fixtureId}`);
  }

  getFixtureTimeline(fixtureId: number): Observable<FixtureTimelineDataI> {
    return this.api.post<FixtureTimelineDataI>(`api/fixture/events/${fixtureId}`);
  }

  getFixtureStats(fixtureId: number): Observable<FixtureStatsDataI> {
    return this.api.post<FixtureStatsDataI>(`api/fixture/stats/${fixtureId}`);
  }
}
