import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  UrlSegment,
  CanActivateChild,
  CanLoad
} from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()

export class AuthSidGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const uid = route.paramMap.get('uid') as string;
    const sid = route.paramMap.get('sid') as string;
    return this.checkSidUidAuth(sid, uid);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const uid = childRoute.paramMap.get('uid') as string;
    const sid = childRoute.paramMap.get('sid') as string;
    return this.checkSidUidAuth(sid, uid);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> {
    const uid = segments[0].parameterMap.get('uid') as string;
    const sid = segments[0].parameterMap.get('sid') as string;
    return this.checkSidUidAuth(sid, uid);
  }

  private checkSidUidAuth(sid: string, uid: string): boolean | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.sidUidLogin({sid, uid})
        .subscribe((res) => {
          if (res.ok) {
            this.authService.isAuthenticated$.next(res.ok);
          }
          resolve(res.ok);
        });
    });
  }
}
