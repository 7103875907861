import {InjectionToken} from '@angular/core';

export interface StorageConfig {
    rootKey: string;
    encryptValue: boolean;
}

export const STORAGE_CONFIG = new InjectionToken('StorageConfig');


export abstract class StorageService {

  abstract length: number;

  abstract getItem(key: string): any;

  abstract setItem(key: string, val: any): void;

  abstract removeItem(key: string): void;

  abstract clear(): void;

  abstract key(val: number): string | null;

  protected abstract buildKey(key: string): string;

  protected abstract encrypt(val: any): string;

  protected abstract decrypt(val: string): any;
}
