import { Injectable } from '@angular/core';
import {ApiService} from '../api';
import { LeaderList } from './leaderboard.types';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

  constructor(private api: ApiService) {
  }

  getLeaderboardList(leagueMatchdayId: number, type: 'all' | 'friend'){
    return this.api.post<{ok: boolean; data: LeaderList }>('api/leaderboard/matchday', {leagueMatchdayId, type});
  }
}
