import { Injectable } from '@angular/core';
import {ApiService} from '../api';
import {Observable} from 'rxjs';
import {LeagueI, LeaguesListI, PopularLeagueI, UserLeagueI} from './league.types';
import { MatchdayLeague, MatchdayLeagueType, SportCountryI } from '../fixture';

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  constructor(private api: ApiService) { }

  getCountryList() {
    return this.api.post<SportCountryI[]>('api/countries/list');
  }

  getLeaguesByCountry(name: string, code: string) {
    return this.api.post<LeaguesListI>(`api/leagues/list/${name}/${code}`);
  }

  getUserLeagues(){
    return this.api.post<UserLeagueI[]>('api/user/leagues');
  }

  getMatchDayLeagues(type: MatchdayLeagueType){
    return this.api.post<MatchdayLeague[]>('api/matchday/leagues', {type});
  }

  getMatchDayLeague(id: number){
    return this.api.post<{ok: boolean; data: MatchdayLeague}>(`api/matchday/league/${id}`,);
  }

  getPopularLeagues(){
    return this.api.post<PopularLeagueI[]>(`api/leagues/list/top`,);
  }

  follow(leagueId: string): Observable<any> {
    return this.api.post('api/user/league/follow/' + leagueId);
  }

  unfollow(leagueId: string): Observable<any> {
    return this.api.post('api/user/league/unfollow/' + leagueId);
  }
}
