export const ERRORS_MESSAGE = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOT_AUTHORIZED: 'wrong sid',
};

export interface ErrorMessage{
  type: string;
  message: string;
}

export interface ErrorMessageList{
  [key: string]: ErrorMessage[];
}
