import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ERRORS_MESSAGE} from '../util';
import {RoutesPath} from '../../../routes';
import {AUTH_ERROR_EVENT, EventService} from '../services/event';


@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly event: EventService,
    private readonly router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err?.error?.message === ERRORS_MESSAGE.NOT_AUTHORIZED || err?.message === ERRORS_MESSAGE.NOT_AUTHORIZED) {
            this.event.publish(AUTH_ERROR_EVENT);
            this.router.navigate([`/${RoutesPath.welcome}`]);
          }
          return throwError(err.error);
        } ));
  }
}
