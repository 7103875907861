import {Injectable} from '@angular/core';
import {environment} from '../../../../environments';
import {StorageService} from './storage.types';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService {

  constructor() {
    super();
  }

  get length(): number {
    return localStorage.length;
  }

  getItem(key: string): any {
    const itemKey = this.buildKey(key);
    const val = localStorage.getItem(itemKey);
    if (!val) { return val; }
    if (environment.production) {
      return this.decrypt(val);
    } else {
      return JSON.parse(val);
    }
  }

  setItem(key: string, val: any): void {
    const itemKey = this.buildKey(key);
    if (environment.production) {
      val = this.encrypt(val);
      return localStorage.setItem(itemKey, val);
    } else {
      return localStorage.setItem(itemKey, JSON.stringify(val));
    }
  }

  removeItem(key: string): void {
    const itemKey = this.buildKey(key);
    localStorage.removeItem(itemKey);
  }

  clear(): void {
    localStorage.clear();
  }

  key(val: number): string | null {
    return localStorage.key(val);
  }

  protected buildKey(key: string): string {
    return `app-${key}`;
  }

  protected encrypt(val: any): string {
    return JSON.stringify(val);
  }

  protected decrypt(val: string): any {
    return JSON.parse(val);
  }
}
