import { Injectable } from '@angular/core';
import {WindowService} from '../window';
import {ModalController} from '@ionic/angular';
import {AddToHomescreenComponent} from '../../components/add-to-homescreen/add-to-homescreen.component';

@Injectable({
  providedIn: 'root'
})
export class AddToHomeScreenService {

  get deferredPrompt(): any {

    if (this._deferredPrompt) {
      return this._deferredPrompt;
    } else {
      // @ts-ignore
      this._deferredPrompt = window._deferredPrompt;
      return this._deferredPrompt;
    }
  }

  set deferredPrompt(value: any) {
    this._deferredPrompt = value;
    // @ts-ignore
    window._deferredPrompt = value;
  }

  ready = false;
  addToHomeScreens: any;
  promptIntercepted = false;

  isStandalone = false;
  userInstalled = false;

  promptSaved = false;

  // tslint:disable-next-line:variable-name
  private _deferredPrompt: any;

  constructor(private windowService: WindowService, public modalController: ModalController) {
    this.windowService.checkUserAgent();
  }

  showAddToHomeScreen() {
    if (this.canShow()) {
      this.saveShown();
      if (this.windowService.isIOS) {
        this.showAddToHomeScreenCustom();
      } else {
        this.showAddToHomeScreenDefault();
      }
    } else {
      console.log('Already shown');
    }
  }

  saveShown(){
    sessionStorage.setItem('shownAddToHomeScreen', 'true');
  }

  canShow() {
    return (sessionStorage.getItem('shownAddToHomeScreen') !== 'true');
  }

  showAddToHomeScreenDefault() {
    if (!this.isStandalone && this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
        .then((choiceResult: any )=> {
          if (choiceResult.outcome === 'accepted') {
            console.log('user accepted A2HS prompt');
          } else {
            console.log('user dismissed A2HS prompt');
          }
          this.deferredPrompt = null;
        });
    }
  }

  showAddToHomeScreenCustom() {
    if (!this.isStandalone) {
      this.modalController.create({
        cssClass: 'alert-modal',
        component: AddToHomescreenComponent,
      }).then((modal) => {
        modal.present();
      });
    }
  };


  isShown() {
    if (this.addToHomeScreens) {
      return this.addToHomeScreens.shown;
    }
  }

  remove() {
    return this.addToHomeScreens.remove();
  }


  trackStandalone() {
    // called once from app.component
    if (this.checkStandalone()) {
      this.isStandalone = true;
      // this.gas.emitEvent('A2HS', 'Standalone', '' , 0);
    }
  }

  checkStandalone(): boolean {
    if (window.matchMedia) {
      return (window.matchMedia('(display-mode: standalone)').matches);
    } else {
      return false;
    }
  }

  trackInstalled() {
    this.userInstalled = true;
  }
}
