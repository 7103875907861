
export const onlyUnique = (value: any, index: number, self: any[]): boolean => (self.indexOf(value) === index);

export const deepClone = <T>(data: T): T =>  (JSON.parse(JSON.stringify(data)));

export const shuffleArray = (arr: any[]): any[] => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};
