import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {API_CONFIG, ApiConfig} from './api.types';
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './api.service';

@NgModule({
    declarations: [],
    exports: [ ],
    imports: [
      HttpClientModule
    ]
})
export class ApiModule {
    constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config: ApiConfig): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: API_CONFIG,
                    useValue: config
                },
              ApiService
            ]
        };
    }
}
