import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ToastOptions} from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private myToast: any;

  constructor(public toastController: ToastController) {}

  presentToast(message: string, opt?: ToastOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      const toastOpt: ToastOptions = Object.assign({message}, opt);
      this.toastController
        .create(toastOpt)
        .then((toastData) => {
          if (!!this.myToast?.dismiss) {
            this.myToast.dismiss();
          }
          this.myToast = toastData;
          this.myToast.present().then(() => (resolve()));
        });
    });
  }

  presentError(errorMsg: string, handler?: () => void): Promise<void> {
    return this.presentToast(errorMsg, {
      position: 'top',
      cssClass: 'error-toast',
      color: 'danger',
      buttons: [{
        text: 'OK',
        role: 'cancel',
        handler: () => (handler && handler())
      }]
    });
  }

  presentSuccess(msg: string): Promise<void> {
    return this.presentToast(msg, {
      position: 'top',
      duration: 5000,
      cssClass: 'success-toast',
      color: 'success',
    });
  }

  presentInfo(msg: string, opt: ToastOptions = {}): Promise<void> {
    const tOpt: ToastOptions = Object.assign({
      position: 'top',
      duration: 5000,
      cssClass: 'info-toast',
      color: 'tertiary',
    }, opt);
    return this.presentToast(msg, tOpt);
  }

  hideToast(): void {
    if (this.myToast?.dismiss) {
      this.myToast.dismiss();
    }
    try{
      this.myToast = this.toastController.dismiss();
      this.myToast.catch((e: any) => {
        console.log(e);
      });
    }catch (e) {
      console.log(e);
    }
  }
}
