import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CredentialUserResponse, ValidationConstants } from './validation.types';
import { ApiService } from '../api';
import { Observable } from 'rxjs';
// @ts-ignore
import libPhoneNumber from 'google-libphonenumber';

const phoneNumberUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(public api: ApiService) {
  }

  static email(c: AbstractControl): ValidationErrors | null {
    const val = c?.value?.trim();
    return val && ValidationConstants.EMAIL_REGEXP.test(val) ? null : {
      email: {
        valid: false
      }
    };
  }

  static emailOrEmpty(c: AbstractControl): ValidationErrors | null {
    const val = c?.value?.trim();
    if(!val) {
      return null;
    }
    return val && ValidationConstants.EMAIL_REGEXP.test(val) ? null : {
      email: {
        valid: false
      }
    };
  }

  static phone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      const value = control.value.trim();
      const validNumber = ValidationService.validatePhone(value);

      return validNumber ? null : {phone: {value}};
    };
  }

  private static validatePhone(phone: string): boolean {
    let validNumber = false;
    let regionCode = 'us';
    const UA = new RegExp(/^\+380/);
    if (UA.test(phone)) {
      regionCode = 'ua';
    }
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        phone, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) {
    }
    return validNumber;
  }

  isUsernameRegistered(username: string): Observable<{ isRegistered: boolean }> {
    return this.api.post(`auth/registered/username/${username}`, {username});
  }

  uniqueUsername(): any {
    return (c: FormControl) => {
      const username = c.value.trim();
      return new Promise((resolve, reject) => {
        if (username === '') {
          return resolve(null);
        }
        this.isUsernameRegistered(username)
          .subscribe((data) => (
            resolve(!data?.isRegistered ? null : {
              uniqueUsername: true
            })));
      });
    };
  }
}
