import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';

/**
 * https://medium.com/swlh/how-to-toggle-caching-for-routing-components-in-angular-5a327ea87310
 */

export class CustomReusingStrategy implements RouteReuseStrategy {
  private cache: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!(route?.routeConfig?.data && route.routeConfig.data.reuse);
  }
  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    if (handler) {
      const url = this.getUrl(route) as string;
      this.cache[url] = handler;
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const url = this.getUrl(route) as string;
    return !!this.cache[url];
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }
    const url = this.getUrl(route) as string;
    return this.cache[url];
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    if (
      future.routeConfig &&
      future.routeConfig.data &&
      future.routeConfig.data.reuse !== undefined
    ) {
      return future.routeConfig.data.reuse;
    }
    return future.routeConfig === current.routeConfig;
  }
  getUrl(route: ActivatedRouteSnapshot): string | void{
    if (route.routeConfig) {
      return route.routeConfig.path;
    }
  }
}
