import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  // user agent
  isChrome = false;
  isExplorer = false;
  isExplorer11 = false;
  isFirefox = false;
  isSafari = false;
  isOpera = false;
  isEdgeDesktop = false;
  isEdgeiOS = false;
  isEdgeAndroid = false;
  userAgent = '';
  osVersion: null | string | RegExpMatchArray | number = '';

  isIOS = false;
  isMobile = false;

  constructor() {
    this.checkUserAgent();
  }

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;

    this.isChrome = /chrome/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer11 = /rv:11/.test(uaString);
    this.isFirefox = /firefox/.test(uaString);
    this.isSafari = /safari/.test(uaString);
    this.isOpera = /opr/.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeiOS = /edgios/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);

    this.isIOS = /ipad|iphone|ipod|macintosh/.test(uaString);
    this.isMobile = /mobile/.test(uaString);
    if ((this.isChrome) && (this.isSafari)) {
      this.isSafari = false;
    }
    if ((this.isChrome) && ((this.isEdgeDesktop) ||
      (this.isEdgeiOS) ||
      (this.isEdgeAndroid))) {
      this.isChrome = false;
    }
    if ((this.isSafari) && ((this.isEdgeDesktop) ||
      (this.isEdgeiOS) ||
      (this.isEdgeAndroid))) {
      this.isSafari = false;
    }
    if ((this.isChrome) && (this.isOpera)) {
      this.isChrome = false;
    }

    this.osVersion = uaString.match(/(OS|Android) (\d+[_\.]\d+)/);
    this.osVersion = this.osVersion && this.osVersion[2] ? +this.osVersion[2].replace('_', '.') : 0;
  }
}
