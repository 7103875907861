import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const takeSnapshot = <T>(value$: Observable<T>, defaultValue?: T): T | null => {
    let result: T | null = defaultValue || null;
    value$.pipe(
        take(1)
    ).subscribe(value => {
        result = value;
    });
    return result;
};
